<template>
    <div class="mb-5">
        <Sidebar />
        <div class="home mb-3" style="margin-bottom:20px">
            <Navbar />
            <Breadcum />
            <AlertErrors />
            <router-view class="mt-4 mb-5"/>
        </div>
    </div>
</template>
<script>
import Sidebar from '../components/layout/Sidebar.vue'
import Navbar from '../components/layout/Navbar.vue'
import Breadcum from '../components/layout/Breadcum.vue'
export default {
    components: {
        Sidebar,
        Navbar,
        Breadcum
    }
}
</script>
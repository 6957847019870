<template>
    <div>
        <div>
            <img class="w-70 img-logo" src="@/assets/images/logo-horizontal.png" alt="">
        </div>
        <div class="item-menu-open" @click="isOpen = !isOpen">
            <svg width="20" height="20" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3242 28V28C9.69622 28 4.32422 22.628 4.32422 16V16C4.32422 9.372 9.69622 4 16.3242 4V4C22.9522 4 28.3242 9.372 28.3242 16V16C28.3242 22.628 22.9522 28 16.3242 28Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21.6579 16.0007H10.9912" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.6572 12L21.6572 16L17.6572 20" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <nav class="sidebar" :id=" isOpen == true ? 'open-sidebar' : ''">
            <svg width="120" height="164" viewBox="0 0 120 164" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 164H119.988V155.765C119.988 151.017 121.487 115.513 91.4905 92.5107C59.9938 66.0076 52.9945 70.0081 29.9969 50.0058C6.99928 30.0035 0 0 0 0V164Z" fill="#0A112D" fill-opacity="1"/>
            </svg>
            <div class="sidebar-bg">
                <div class="menu-bar">
                    <div class="menu">
                        <User @hide="isOpen = !isOpen" />
                        <!-- <Admin v-if="user.role == 1"/> -->
                    </div>
                </div>
            </div>
            <svg width="120" height="164" viewBox="0 0 120 164" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 1.52588e-05L119.988 1.52588e-05V8.23543C119.988 12.9826 121.487 48.4867 91.4905 71.4893C59.9938 97.9924 52.9945 93.9919 29.9969 113.994C6.99928 133.997 0 164 0 164L0 1.52588e-05Z" fill="#0A112D" fill-opacity="1"/>
            </svg>
        </nav>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Admin from './partials/Admin.vue'
import User from './partials/User.vue'
export default {
    props: {
        isOpen: {
            default: false
        }
    },
    components: {
        Admin,
        User
    },
    data () {
        return {
        }
    },
    created () {
        this.getUserInfo().then(() => {
            /* if(this.user.code != null) {
                this.logout()
            } */
            console.log("HOLI")
        })
    },
    methods: {
        ...mapActions('auth', ['getUserInfo', 'logout']),
        changeBar () {
            this.isOpen = !this.isOpen
        }
    },
    computed: {
        ...mapState('auth', ['user'])
    }

}
</script>
<style>
    .img-logo{
        position: absolute;
        left: 20px;
        max-width: 100px;
        top: 30px;
    }
</style>
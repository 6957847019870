<template>
  <ul class="menu-links">
    <li class="nav-link">
      <router-link @click="$emit('hide')" :to="{ name: 'Dashboard' }">

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 17.75H13C11.895 17.75 11 16.855 11 15.75V13C11 11.895 11.895 11 13 11H15.75C16.855 11 17.75 11.895 17.75 13V15.75C17.75 16.855 16.855 17.75 15.75 17.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M27 17.75H24.25C23.145 17.75 22.25 16.855 22.25 15.75V13C22.25 11.895 23.145 11 24.25 11H27C28.105 11 29 11.895 29 13V15.75C29 16.855 28.105 17.75 27 17.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 29H13C11.895 29 11 28.105 11 27V24.25C11 23.145 11.895 22.25 13 22.25H15.75C16.855 22.25 17.75 23.145 17.75 24.25V27C17.75 28.105 16.855 29 15.75 29Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M27 29H24.25C23.145 29 22.25 28.105 22.25 27V24.25C22.25 23.145 23.145 22.25 24.25 22.25H27C28.105 22.25 29 23.145 29 24.25V27C29 28.105 28.105 29 27 29Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        
        <div class="text nav-text">Dashboard</div>

      </router-link>
    </li>
    <li class="nav-link">
      <router-link @click="$emit('hide')" :to="{ name: 'Wallet' }">

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M28 23.5H26C24.895 23.5 24 22.605 24 21.5V21.5C24 20.395 24.895 19.5 26 19.5H28C28.552 19.5 29 19.948 29 20.5V22.5C29 23.052 28.552 23.5 28 23.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M28 19.5V16.5C28 15.395 27.105 14.5 26 14.5H12.5C11.672 14.5 11 13.828 11 13V13C11 12.172 11.672 11.5 12.5 11.5H25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M28 23.5V26.5C28 27.605 27.105 28.5 26 28.5H13C11.895 28.5 11 27.605 11 26.5V13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        
        <div class="text nav-text">Wallet</div>

      </router-link>
    </li>
    <li class="nav-link">
      <router-link @click="$emit('hide')" :to="{ name: 'Staking' }">

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="12" y="11" width="16" height="18" rx="2" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M16 25H24" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2152 14.9878C19.3626 14.6891 19.6668 14.5 19.9999 14.5C20.333 14.5 20.6372 14.6891 20.7845 14.9878L21.2297 15.8903C21.3572 16.1486 21.6035 16.3277 21.8886 16.3691L22.8844 16.5138C23.2139 16.5618 23.4877 16.7926 23.5906 17.1092C23.6936 17.4259 23.6079 17.7735 23.3695 18.006L22.6486 18.7092C22.4425 18.9102 22.3485 19.1998 22.3972 19.4835L22.5673 20.4751C22.6235 20.8033 22.4886 21.1351 22.2192 21.3308C21.9498 21.5266 21.5927 21.5524 21.2979 21.3975L20.4069 20.9293C20.1521 20.7954 19.8477 20.7954 19.5928 20.9293L18.7019 21.3975C18.4071 21.5524 18.0499 21.5266 17.7805 21.3308C17.5111 21.1351 17.3762 20.8033 17.4325 20.4751L17.6026 19.4835C17.6512 19.1998 17.5572 18.9102 17.3511 18.7092L16.6302 18.006C16.3919 17.7735 16.3062 17.4259 16.4091 17.1092C16.512 16.7926 16.7858 16.5618 17.1153 16.5138L18.1112 16.3691C18.3962 16.3277 18.6426 16.1486 18.77 15.8903L19.2152 14.9878Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        
        <div class="text nav-text">Staking</div>

      </router-link>
    </li>
    <li class="nav-link">
      <router-link @click="$emit('hide')" :to="{ name: 'P2P' }">

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.0018 9.99585L22.001 11.9967L24.0018 13.9975" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M28.0035 11.9968H22.001" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M29.004 20.0002C29.0031 18.6109 28.6789 17.2409 28.057 15.9985" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M17.999 11.2273C13.9039 12.1598 10.998 15.8003 10.9961 20.0002" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.998 30.0044L17.9989 28.0035L15.998 26.0027" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11.9971 28.0034H17.9996" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.9964 20C10.9973 21.3893 11.3215 22.7593 11.9434 24.0017" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22.001 28.7729C26.0961 27.8404 29.002 24.1999 29.0039 20" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0603 16.4379L23.5617 18.9386C23.8432 19.22 24.0014 19.6016 24.0014 19.9996C24.0014 20.3976 23.8434 20.7794 23.562 21.0608L21.0613 23.5622C20.78 23.8437 20.3983 24.0019 20.0003 24.0019C19.6023 24.0019 19.2206 23.8439 18.9391 23.5625L16.4377 21.0618C16.1563 20.7805 15.998 20.3988 15.998 20.0008C15.998 19.6028 16.156 19.2211 16.4374 18.9396L18.9381 16.4382C19.2195 16.1568 19.6012 15.9985 19.9992 15.9985C20.3972 15.9985 20.7789 16.1565 21.0603 16.4379V16.4379Z" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        
        <div class="text nav-text">P2P</div>

      </router-link>
    </li>
    <li class="nav-link">
      <router-link @click="$emit('hide')" :to="{ name: 'Chat' }">

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M23 27H19C17.895 27 17 26.105 17 25V19.5C17 18.395 17.895 17.5 19 17.5H27C28.105 17.5 29 18.395 29 19.5V25C29 26.105 28.105 27 27 27H26V29L23 27Z" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M25.609 17.5V13.435C25.609 12.09 24.519 11 23.174 11H13.435C12.09 11 11 12.09 11 13.435V20.131C11 21.476 12.09 22.566 13.435 22.566H14.652V25L17 23.435" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        
        <div class="text nav-text">Chats</div>

      </router-link>
    </li>
    <li class="nav-link">
      <router-link @click="$emit('hide')" :to="{ name: 'Support' }">

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.0037 20C29.0037 24.9726 24.9726 29.0037 20 29.0037" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.9961 20.0001C10.9961 15.0275 15.0272 10.9963 19.9998 10.9963" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.9961 20.0002C10.9962 21.0549 11.1838 22.1011 11.5501 23.0901C11.7176 23.5494 12.1899 23.823 12.6717 23.7398L13.6583 23.5658C14.4557 23.4252 14.9977 22.6773 14.8832 21.8758L14.5863 19.7972C14.5288 19.3948 14.3105 19.0329 13.9814 18.7944C13.6523 18.5558 13.2405 18.461 12.8402 18.5316L11.0788 18.8422" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M29.0037 20.0001C29.0037 15.0275 24.9726 10.9963 20 10.9963" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M29.0039 20.0002C29.0038 21.0549 28.8162 22.1011 28.4499 23.0901C28.2824 23.5494 27.8102 23.823 27.3284 23.7398L26.3418 23.5658C25.5444 23.4252 25.0023 22.6773 25.1168 21.8758L25.4138 19.7972C25.4713 19.3948 25.6895 19.0329 26.0186 18.7944C26.3478 18.5558 26.7596 18.461 27.1599 18.5316L28.9212 18.8422" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M21.4934 20.4913C21.4904 20.4913 21.4876 20.4931 21.4865 20.4959C21.4853 20.4987 21.4859 20.5019 21.4881 20.504C21.4902 20.5062 21.4934 20.5068 21.4962 20.5057C21.499 20.5045 21.5008 20.5018 21.5008 20.4987C21.501 20.4967 21.5002 20.4948 21.4988 20.4933C21.4974 20.4919 21.4954 20.4912 21.4934 20.4913" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18.507 20.4942C18.504 20.4942 18.5013 20.496 18.5001 20.4988C18.499 20.5016 18.4996 20.5048 18.5018 20.507C18.5039 20.5091 18.5071 20.5097 18.5099 20.5086C18.5127 20.5074 18.5145 20.5047 18.5145 20.5017C18.5146 20.4997 18.5139 20.4977 18.5125 20.4962C18.511 20.4948 18.5091 20.4941 18.507 20.4942" stroke="#BDBBCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        
        <div class="text nav-text">Support</div>

      </router-link>
    </li>
  </ul>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('auth', ['user'])
  }
}
</script>